<template>
  <b-row>
    <b-col md="12">
      <b-card
        title="Filtrele"
      >
        <b-form-group
          label="Anahtar Kelime"
          label-for="keywords"
        >
          <b-form-input
            id="customer_name"
            v-model="dataQuery.customer_name"
            placeholder="Müşteri & Firma adı, telefon numarası.."
          />
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group
              label="Kampanya Türü"
              label-for="id_com_coupon_type"
            >
              <v-select
                id="id_com_coupon_type"
                v-model="dataQuery.id_com_coupon_type"
                placeholder="Kampanya türü"
                :options="couponTypes"
                :reduce="item => item.id"
                label="title"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Kullanım Durumu"
              label-for="used"
            >
              <v-select
                id="used"
                v-model="dataQuery.used"
                placeholder="Kullanım Durumu"
                :options="usedStatuses"
                :reduce="item => item.id"
                label="title"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>

          <b-col class="text-center">
            <b-button
              variant="primary"

              @click="getDataList"
            >
              <FeatherIcon icon="FilterIcon" />
              Filtrele
            </b-button>
          </b-col>

        </b-row></b-card>
      <b-card
        no-body
      >
        <b-card-header>
          <b-card-title>SMS Listesi</b-card-title>
        </b-card-header>
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
        >
          <template #cell(used)="data">
            <div
              :class="data.item.used == 'Kullanıldı' ? 'text-success' : data.item.used == 'Süresi Doldu' ? 'text-danger' : 'text-primary'"
            >
              {{ data.item.used }}

            </div>
          </template>
          <template #cell(code)="data">
            <div
              :class="data.item.code ? 'text-warning' : ''"
            >
              {{ data.item.code }}

            </div>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCounts"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BFormInput, BButton, BFormGroup, BCardTitle, BCardHeader,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    BCardHeader,
    BCardTitle,
    vSelect,
    BFormGroup,
    BButton,
    BFormInput,
    BPagination,
    BCardFooter,
    BCard,
    BTable,
    BRow,
    BCol,
  },
  data() {
    return {
      fields: [
        {
          key: 'customer',
          label: 'Adı Soyadı',
        },
        {
          key: 'coupon_type',
          label: 'Kampanya Tipi',
        },
        {
          key: 'phone',
          label: 'Telefon',
        },
        {
          key: 'code',
          label: 'Kod',
        },
        {
          key: 'edate',
          label: 'Bitiş Tarihi',
        },
        {
          key: 'used',
          label: 'Durum',
        },
        {
          key: 'created',
          label: 'Oluşturma',
        },
      ],
      currentPage: 1,
      perPage: 50,
      dataQuery: {
        start: 0,
        limit: 50,
        id_com_coupon_type: null,
        customer_name: null,
        phone: null,
        used: null,
      },
      usedStatus: [
        {
          id: 1,
          title: 'Kullanıldı',
        },
        {
          id: 2,
          title: 'Kullanılmadı',
        },
        {
          id: 3,
          title: 'Süresi Doldu',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['campaignSms/getDataList']
    },
    dataCounts() {
      return this.$store.getters['campaignSms/dataCounts']
    },
    couponTypes() {
      return this.$store.getters['campaignSms/getCouponTypes']
    },
    usedStatuses() {
      return this.usedStatus
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 50
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
    this.getCouponTypes()
  },
  methods: {
    getDataList() {
      window.scrollTo(0, 0)
      this.$store.dispatch('campaignSms/dataList', this.dataQuery)
    },
    getCouponTypes() {
      this.$store.dispatch('campaignSms/couponTypes', {
        select: [
          'com_coupon_type.id        AS id',
          'com_coupon_type.title     AS title',
        ],
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
  },
}
</script>
